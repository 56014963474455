import React from "react"
import { BsPencilSquare } from "react-icons/bs"

const startupServicesList = [
  {
    icon: <BsPencilSquare />,
    title: "創業計画支援",
    description:
      "創業に必要な事業計画書作成・分析・リサーチのお手伝いをいたします。",
  },
]
export default startupServicesList

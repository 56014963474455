import React from "react"
import { Parallax } from "react-parallax"
import bgImage from "../../assets/images/start-up.jpg"
import { Link } from "gatsby"
import Services from "../../components/Services"
import startupServicesList from "../../constants/startup-service"
import Seo from "../../components/utilities/Seo"
const StartUpSupport = () => {
  return (
    <section className="start-up">
      <Seo title="創業支援" />
      <Parallax
        bgImage={bgImage}
        bgImageAlt="shopify"
        strength={400}
        blur={{ min: 0, max: 1 }}
      >
        <div className="parallax-box">
          <div className="parallax-info">
            <h1>創業支援</h1>
            <h2>スタートアップ支援</h2>
            <p>
              新しくビジネスをスタート・成長させるためのサポートを行います。
            </p>
            <div>
              <Link to="/contact" className="btn-primary">
                お問合せ
              </Link>
            </div>
          </div>
        </div>
      </Parallax>
      <Services servicesList={startupServicesList} />
    </section>
  )
}

export default StartUpSupport
